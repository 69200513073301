import { StorageInterface } from "@/interfaces/storage";
import localStorageInterface from "@/utils/storage/localStorageInterface";

const storage = (storageInterface: StorageInterface) => {
  return {
    get: (key: string) => storageInterface.getByKey(key),
    set: (key: string, value: any) => storageInterface.set(key, value),
  };
};

export const storageLocal = storage(localStorageInterface());
