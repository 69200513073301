<template>
  <NewFeatureDialog name="longperiods" :maxViews="3" :urlMatcher="/resultados/">
    <template #default>
      <div v-if="isMobile == false" class="grid grid-cols-2 items-center justify-center">
        <div class="justify-self-center text-7xl text-primary-default sm:col-span-2 md:col-span-1">
          Novas opções de períodos
        </div>

        <div class="justify-self-center sm:col-span-2 md:col-span-1">
          <img
            src="https://storage.googleapis.com/elt-smb-prod/assets/new-features/2024/10/gif-semanas.gif"
            alt="Gif ilustrando um botão de seleção de duração de campanhas de longos periodos 12 e 24 semanas."
            style="height: 320px"
          />
        </div>
      </div>

      <div v-if="isMobile == true" class="grid items-center justify-center">
        <div class="justify-self-center text-7xl text-primary-default sm:col-span-2 md:col-span-1">
          Novas opções de períodos
        </div>
        <img
          src="https://storage.googleapis.com/elt-smb-prod/assets/new-features/2024/10/gif-semanas.gif"
          alt="Gif ilustrando um botão de seleção de duração de campanhas de longos periodos 12 e 24 semanas."
          style="height: 320px"
        />
      </div>
    </template>

    <template #footer>
      Agora você pode selecionar entre 2, 4, 12 ou 24 semanas para veicular o seu anúncio nas telas da Eletromidia
    </template>

    <template #cta> </template>
  </NewFeatureDialog>
</template>

<script setup lang="ts">
import NewFeatureDialog from "@components/Dialogs/NewFeatureDialog.vue";

import longPeriodsImage from "@/assets/new-features/long-periods.png";
import { useIsMobile } from "@/hooks";
const { isMobile } = useIsMobile();
</script>

<style scoped lang="scss"></style>
