import { StorageInterface } from "@/interfaces/storage";

const ls = window.localStorage;

const localStorageInterface = (): StorageInterface => {
  const getByKey = (key: string) => {
    const json = JSON.parse(ls.getItem(key));
    return json;
  };
  const set = (key: string, value: any) => {
    ls.setItem(key, JSON.stringify(value));
  };

  return {
    getByKey,
    set,
  };
};

export default localStorageInterface;
