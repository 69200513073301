<template>
  <Dialog
    v-model:visible="isDialogOpen"
    :draggable="false"
    :breakpoints="{}"
    :class="dialogSizes"
    modal
    :closable="false"
    closeOnEscape
    dismissableMask
    class="new-features-dialog"
  >
    <template #header> </template>

    <div class="px-6">
      <slot name="default" />
    </div>

    <template #footer>
      <div class="feature-dialog-footer flex flex-col p-6">
        <div class="flex justify-center pb-8 text-left text-xl lg:text-center">
          <slot name="footer" />
        </div>

        <div class="flex items-center justify-between">
          <div class="flex flex-1">
            <FormItem
              v-model="nevershowagain"
              type="checkbox"
              name="nevershowagain"
              :options="[optionFactory('Não exibir novamente', 1)]"
            />
          </div>

          <div class="flex flex-1 flex-row justify-end">
            <Button type="button" label="fechar" class="feature-dialog-close-button mr-6" link @click="closeDialog" />
            <slot name="cta" />
          </div>
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script setup>
import FormItem from "@components/FormItem/FormItem.vue";
import { optionFactory } from "@components/FormItem/optionFactory";
import { computed, onMounted, ref } from "vue";

import { addDays } from "@/utils/dateparser/dateHelper";
import dateParser from "@/utils/dateparser/dateparser";
import { storageLocal } from "@/utils/storage/storage";

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  maxViews: {
    type: Number,
    required: false,
    default: 3,
  },
  expire: {
    type: Date,
    required: false,
    default: () => addDays(new Date(), 14),
  },
  urlMatcher: {
    type: RegExp,
    required: false,
    default: undefined,
  },
});
const emit = defineEmits(["update:isVisible"]);

const currentPath = window.location.pathname;
const dialogSizes = ["w-11/12", "md:w-3/4", "lg:w-2/3", "xl:w-2/4"];

const nevershowagain = ref(false);
const dialogVisibility = ref(true);
const feature = ref();

const featureStorageKey = computed(() => `featureDisplay_${props.name}`);
const hasViewsAvailable = computed(() => feature.value?.view_count < feature.value?.max_views);
const isNotExpired = computed(() => feature.value?.expire?.getTime() >= new Date().getTime());
const isUrlMatched = props.urlMatcher.test(currentPath);

const isDialogOpen = computed({
  get() {
    const show = isNotExpired.value && isUrlMatched && hasViewsAvailable.value && dialogVisibility.value;
    return show;
  },
  set(value) {
    emit("update:isVisible", value);
    closeDialog();
  },
});

const openDialog = () => (dialogVisibility.value = true);
const closeDialog = () => {
  let newViewCount = feature.value.view_count + 1;
  if (nevershowagain.value[0]) {
    newViewCount = feature.value.view_count = feature.value.max_views;
  }

  dialogVisibility.value = false;
  feature.value.view_count = newViewCount;
  persistFeatureData();
};

const getDefaultFeatureData = () => ({
  name: props.name,
  expire: props.expire,
  max_views: props.maxViews,
  view_count: 0,
});

const persistFeatureData = () => storageLocal.set(featureStorageKey.value, feature.value);

onMounted(() => {
  let featureStorage = storageLocal.get(featureStorageKey.value);
  if (featureStorage) {
    featureStorage.expire = dateParser(featureStorage.expire);
  } else {
    featureStorage = getDefaultFeatureData();
  }

  feature.value = featureStorage;
  persistFeatureData();
});
</script>

<style lang="scss">
@import "@/styles/themes/tailwind/tailwind-light/_variables.scss";

.new-features-dialog.p-dialog {
  background-color: #1caf58;
}
.new-features-dialog.p-dialog .p-dialog-footer {
  background-color: $primaryColor;
  & * {
    color: #fff;
  }

  & .feature-dialog-close-button,
  .p-button.p-button-link:hover {
  }
}
</style>
